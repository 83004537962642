import '../index.less';

export default {

    props: {
        visible: { Type: Boolean }
    },
    render() {

        return (
            <a-modal
                ref="modal"
                title="Política de privacidade"
                visible={this.visible}
                onCancel={() => this.$emit('close')}
                footer={null}
                keyboard={false}
                class="termo-modal"
            >
                <div class="text-justify terms-content">
                    <p class="text-h5">1 - Informações gerais</p>
                    <p class="text-body1">
                        A Política de Privacidade corresponde às informações quanto ao cadastramento, uso,
                        armazenamento, tratamento e proteção dos dados pessoais dos usuários registrados no
                        aplicativo <u><b>ZONA SETE VISTORIAS</b></u>, visando esclarecer sobre que tipos de dados
                        serão cadastrados, os motivos de seu registro e a forma como as informações serão gerenciadas,
                        tratadas e após, excluídas.
                    </p>
                    <p class="text-body1">
                        Esta Política de Privacidade aplica-se a todos os usuários do aplicativo, e integra os Termos e
                        Condições Gerais de Uso elaborados pela detentora exclusiva dos direitos autorais, e dos códigos
                        fontes do software de vistorias, denominado GXZ SOFTWARE LTDA., devidamente inscrita no CNPJ
                        sob o nº 27.117.429/0001-10, situado em Rua Auto Curti, 425, Jardim América, Maringá, PR,
                        doravante nominada estipulante.
                    </p>
                    <p class="text-body1">
                        Este instrumento foi elaborado em conformidade com a atual legislação do país, podendo ser
                        atualizada em decorrência de atualizações normativas, razão pela qual, o usuário deve atentar-se
                        periodicamente quanto aos termos expressos nesta seção.
                    </p>
                    <p class="text-h5">2 - Dados pessoais cadastrados pelos usuários e controlador</p>
                    <p class="text-body1">
                        Os dados pessoais cadastrados pelos usuários (locador, locatário, fiadores, intermediador) e
                        pelo controlador na plataforma correspondem:
                    </p>
                    <p class="text-body1">
                        - Aos dados para a criação de conta de acesso – <b><i>login</i> e senha</b>, que são dados
                        pessoais e de inteira responsabilidade dos usuários, que se identificarão em todas as etapas
                        para a efetivação e andamento dos serviços;
                    </p>
                    <p class="text-body1">
                        - Aos dados de registros do locatário para a elaboração do termo de vistoria, como, nome
                        completo, registro civil e CPF;
                    </p>
                    <p class="text-body1">
                        - Endereço do imóvel a ser locado e sua identificação e caracterização;
                    </p>
                    <p class="text-body1">
                        - Dados dos fiadores, nome completo, registro civil e CPF;
                    </p>
                    <p class="text-body1">
                        - Dados do locador, nome completo, registro civil e CPF;
                    </p>
                    <p class="text-body1">
                        - Registros das imagens do imóvel a ser locado;
                    </p>
                    <p class="text-body1">
                        - Aos dados para a concretização e formalização do pagamento dos serviços contratados, como
                        dados referentes ao pagamento e transações, tais como, número do cartão de crédito e outras
                        informações sobre o cartão, além dos pagamentos efetuados;
                    </p>
                    <p class="text-body1">
                        - Aos dados relacionados ao termo de vistoria, que serão autenticados pelos usuários através de
                        assinatura digital;
                    </p>
                    <p class="text-body1">
                        - Como os dados são inseridos pelos usuários, estes são inteiramente responsáveis pela
                        veracidade das informações cadastradas, devendo promover as alterações que se fizerem
                        necessárias para o fiel cumprimento das obrigações correspondentes à locação pretendida.
                    </p>
                    <p class="text-h5">3 - Finalidades dos dados pessoais registrados</p>
                    <p class="text-body1">
                        Os dados registrados pelos usuários dos serviços possuem a seguinte finalidade:
                    </p>
                    <p class="text-body1">
                        - Formalização dos laudos de vistorias, de entrada e de saída dos bens imóveis locados;
                    </p>
                    <p class="text-body1">
                        - Termos de condições do imóvel que passam a integrar o instrumento contratual de locação,
                        durante a permanência do locatário no bem imóvel locado;
                    </p>
                    <p class="text-body1">
                        - Dar acesso aos usuários à plataforma, para a efetivação das vistorias de entrada e saída;
                    </p>
                    <p class="text-body1">
                        - Agendar a realização da vistoria pelo contratado, com a devida cientificação dos interessados
                        através da confirmação do agendamento via plataforma do aplicativo;
                    </p>
                    <p class="text-h5">4 - Tempo de armazenamento dos dados pessoais</p>
                    <p class="text-body1">
                        - Os dados pessoais do usuário e interessados são armazenados pela plataforma durante o período
                        necessário para a prestação do serviço ou o cumprimento das finalidades previstas no contrato de
                        locação, ou seja, durante o prazo legal para prescrição das pretensões de reparações e
                        obrigações das relações locatícias.
                    </p>
                    <p class="text-h5">5 - Segurança dos dados pessoais</p>
                    <p class="text-body1">
                        - A plataforma se compromete a aplicar as medidas técnicas e necessárias que estejam ao seu
                        alcance a proteger os dados pessoais de acessos não autorizados e de situações de destruição,
                        perda, alteração, comunicação ou difusão de tais dados.
                    </p>
                    <p class="text-body1">
                        - Os dados relativos a cartões de crédito são criptografados usando a tecnologia "<i>secure
                        socket layer</i>" (SSL) que garante a transmissão de dados de forma segura e confidencial, de
                        modo que a transmissão dos dados entre o servidor e o usuário ocorre de maneira cifrada e
                        encriptada.
                    </p>
                    <p class="text-body1">
                        - Os dados pessoais armazenados são tratados com confidencialidade, dentro dos limites legais,
                        e para os fins que se destinam em relação às obrigações entre as partes.
                    </p>
                    <p class="text-h5">6 - Compartilhamento dos dados</p>
                    <p class="text-body1">
                        - Os registros dos dados pelos usuários dos serviços, correspondem aos necessários à
                        formalização da relação obrigacional locatícia entre a prestadora de serviços intermediadora –
                        imobiliária e ou corretor de imóveis, o locador, o locatário e os fiadores, se esta forma a
                        modalidade de garantia.
                    </p>
                    <p class="text-body1">
                        - Assim, tais dados serão mantidos para a confecção do instrumento de vistoria de entrada e de
                        saída, em proteção aos interesses e garantias de todas as partes, sendo que tais informações
                        compreendem a relação obrigacional principal, correspondente ao contrato de locação.
                    </p>
                    <p class="text-body1">
                        - A manutenção e a conservação dos dados, far-se-á enquanto perdurar a relação contratual, e
                        pelo prazo legal que extingua os reflexos das obrigações locatícias assumidas.
                    </p>
                    <p class="text-body1">
                        - O compartilhamento de quaisquer dados que aqui se fazem autorizados pelo usuário, apenas
                        correspondem às necessárias às confirmações das transações de pagamentos aos serviços aqui
                        contratados, para a segurança da transação e de confirmação e autenticidade do usuário.
                    </p>
                    <p class="text-h5">7 - Da transferência de dados a terceiros</p>
                    <p class="text-body1">
                        - A plataforma foi desenvolvida e se aplica apenas à realização dos serviços pelos quais foi
                        proposta, ou seja, para a efetivação dos termos de vistorias de entrada e de saída, que passarão
                        a integrar o contrato de locação, durante a vigência das obrigações locatícias.
                    </p>
                    <p class="text-body1">
                        - Os dados armazenados somente serão transferidos a terceiros, se houver o repasse da carteira
                        de locação, do que os interessados e usuários autorizam tal repasse, desde que previsto no
                        contrato principal de locação.
                    </p>
                    <p class="text-body1">
                        - Acaso seja disponibilizado ou redirecionado para um aplicativo ou site de terceiros, tais
                        condições e termos não serão mais regidos por essa Política de Privacidade ou pelos Termos de
                        Serviço desta plataforma, pois não há qualquer relação de solidariedade e ou responsabilidade
                        mútua pelas práticas de privacidade de outros sites, assim orientamos a ler as declarações de
                        privacidade deles.
                    </p>
                    <p class="text-body1">
                        - Com relação aos fornecedores de serviços terceirizados como processadores de transação de
                        pagamento, informamos que cada qual tem sua própria política de privacidade. Desse modo,
                        recomendamos a leitura das suas políticas de privacidade para compreensão de quais informações
                        pessoais serão usadas por esses fornecedores.
                    </p>
                    <p class="text-body1">
                        - No caso de algum dos usuários estiver localizado ou possuir instalações localizadas em países
                        diferentes, nessas condições, os dados pessoais transferidos podem se sujeitar às leis de
                        jurisdições nas quais suas instalações estão localizados, sem prejuízos da aplicação da
                        legislação brasileira.
                    </p>
                    <p class="text-body1">
                        - Ao acessar nossos serviços e prover suas informações, você está consentindo com o
                        processamento, transferência e armazenamento desta informação em outros países, acaso necessário.
                    </p>
                    <p class="text-h5">8 - Cookies e dados de navegação</p>
                    <p class="text-body1">
                        - Os cookies referem-se a arquivos de texto enviados pela plataforma ao computador do usuário e
                        que nele ficam armazenados, com informações relacionadas à navegação no site. Tais informações
                        são relacionadas aos dados de acesso como local e horário de acesso e são armazenadas pelo
                        navegador do usuário para que o servidor da plataforma possa lê-las posteriormente a fim de
                        personalizar os serviços da plataforma.
                    </p>
                    <p class="text-body1">
                        - O usuário da plataforma deve manifestar seu conhecimento e aceitação de que pode ser utilizado
                        um sistema de coleta de dados de navegação mediante à utilização de cookies.
                    </p>
                    <p class="text-body1">
                        - O cookie persistente permanece no disco rígido do depois que o navegador é fechado e será
                        usado pelo navegador em visitas subsequentes ao site. Os cookies persistentes podem ser
                        removidos seguindo as instruções do seu navegador. Já o cookie de sessão é temporário e
                        desaparece depois que o navegador é fechado. É possível redefinir seu navegador da web para
                        recusar todos os cookies, porém alguns recursos da plataforma podem não funcionar corretamente
                        se a capacidade de aceitar cookies estiver desabilitada.
                    </p>
                    <p class="text-h5">9 - Do consentimento</p>
                    <p class="text-body1">
                        - <b>Após ler e confirmar sua aceitação à política de privacidade, além de aceitar cadastrar
                        seus dados para a confecção dos laudos de vistoria, o usuário estará apto à execução dos
                        serviços e a fornecer as informações cadastrais na plataforma</b>.
                    </p>
                    <p class="text-body1">
                        - O usuário, ao cadastrar-se, manifesta conhecer e pode exercitar seu direito de cancelar seu
                        cadastro, acaso haja a desistência da locação proposta, e ou haja a alteração da fiança
                        contratada, acessar e atualizar seus dados pessoais e garante a total veracidade das informações
                        por ele disponibilizadas.
                    </p>
                    <p class="text-body1">
                        - O usuário tem direito de retirar o seu consentimento a qualquer tempo, para tanto deve entrar
                        em contato através do e-mail contato@zonasete.com.br, fato que não exclui as demais obrigações
                        em relação ao laudo de vistoria elaborado, em especial quanto às condições, manutenção e
                        conservação do imóvel, e as demais obrigações principais e acessórias decorrentes do contrato de
                        locação.
                    </p>
                    <p class="text-h5">10 - Alterações da política de privacidade</p>
                    <p class="text-body1">
                        - Reservamos o direito de modificar essa Política de Privacidade a qualquer momento, então, é
                        recomendável que o usuário revise-a com frequência.
                    </p>
                    <p class="text-body1">
                        - As alterações e esclarecimentos vão surtir efeito imediatamente após sua publicação na
                        plataforma. Quando realizadas alterações os usuários serão notificados. Ao utilizar o serviço ou
                        fornecer informações pessoais após eventuais modificações, o usuário demonstra sua concordância
                        com as novas normas.
                    </p>
                    <p class="text-body1">
                        - Diante da fusão ou venda da plataforma à outra empresa os dados dos usuários podem ser
                        transferidos para os novos proprietários, visando a permanência dos serviços oferecidos.
                    </p>
                    <p class="text-h5">11 - Jurisdição para resolução de conflitos</p>
                    <p class="text-body1">
                        - Para a solução de controvérsias decorrentes do presente instrumento será aplicado
                        integralmente a legislação brasileira.
                    </p>
                    <p class="text-body1">
                        - Eventuais litígios deverão ser apresentados no foro da Comarca em que se encontra a Sede da
                        empresa.
                    </p>
                </div>
            </a-modal>
        );
    }
}
