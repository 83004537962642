import '../index.less';

export default {

    props: {
        visible: { Type: Boolean },
        readonly: { Type: Boolean, default: false }
    },
    updated() {

        const target = this.$refs.bottomElement;
        this.observer = new IntersectionObserver(
            (entries, opts) => {

                entries.forEach(entry => {

                    this.scrollToBottom = entry.intersectionRatio >= opts.thresholds[0];
                });
            },
            {root: null, threshold: .5}
        );

        this.observer.observe(target);

    },
    beforeDestroy() {

        if(this.observer) {

            const target = this.$refs.bottomElement;
            this.observer.unobserve(target);
        }
    },
    data() {

        return {
            scrollToBottom: false,
            observer: null
        }
    },
    render() {

        let footerProp = this.readonly ? {footer: null} : {};

        return (
            <a-modal
                ref="modal"
                title="Termos e condições gerais de uso"
                visible={this.visible}
                onCancel={() => this.$emit('acceptTerm', false)}
                onOk={() => this.$emit('acceptTerm', true)}
                okButtonProps={{props: {disabled: !this.scrollToBottom}}}
                okText="Aceito"
                keyboard={false}
                class="termo-modal"
                {...{props: footerProp}}
            >
                <div class="text-justify terms-content">
                    <p class="text-body1">
                        Os serviços de vistorias digitais são fornecidos pela CONTRATADA: GXZ Software Ltda., com nome
                        fantasia GXZ Software, inscrita no CNPJ sob o nº 27.117.429/0001-10, titular da propriedade
                        intelectual do aplicativo relacionado à plataforma ZONA SETE VISTORIAS.
                    </p>
                    <p class="text-h5">1 - Do objeto</p>
                    <p class="text-body1">
                        A plataforma visa licenciar o uso de seu aplicativo, fornecendo ferramentas para facilitar a
                        execução das vistorias dos imóveis residenciais e comerciais pelos usuários contratantes.
                    </p>
                    <p class="text-body1">
                        A plataforma caracteriza-se pela prestação do serviço de vistorias eletrônicas.
                    </p>
                    <p class="text-h5">2 - Da aceitação</p>
                    <p class="text-body1">
                        O presente Termo estabelece obrigações contratadas de livre e espontânea vontade, pelo tempo
                        contratado, entre a plataforma e as pessoas físicas ou jurídicas, usuárias do aplicativo.
                    </p>
                    <p class="text-body1">
                        Ao utilizar a plataforma o usuário aceita integralmente as presentes normas e compromete-se a
                        observá-las, sob o risco de aplicação das penalidades cabíveis.
                    </p>
                    <p class="text-body1">
                        A aceitação do presente instrumento é imprescindível para o acesso e para a utilização de
                        quaisquer serviços fornecidos pela contratada. Caso não concorde com as disposições deste
                        instrumento, o usuário não deve utilizá-los.
                    </p>
                    <p class="text-h5">3 - Do acesso aos usuários</p>
                    <p class="text-body1">
                        Serão utilizadas todas as soluções técnicas à disposição do responsável pela plataforma para
                        permitir o acesso ao serviço 24 (vinte e quatro) horas por dia, 7 (sete) dias por semana.
                        No entanto, a navegação na plataforma ou em alguma de suas páginas poderá ser interrompida,
                        limitada ou suspensa para atualizações, modificações ou qualquer ação necessária ao seu bom
                        funcionamento, bem como, qualquer impossibilidade técnica não prevista ou ocasionada por força
                        maior, isenta a prestadora das indisponibilidades momentâneas incorridas.
                    </p>
                    <p class="text-h5">4 - Do cadastro</p>
                    <p class="text-body1">
                        O acesso às funcionalidades da plataforma exigirá a realização de um cadastro prévio e, a
                        depender dos serviços ou condições escolhidas, além do pagamento de determinado valor contratado.
                    </p>
                    <p class="text-body1">
                        Ao se cadastrar o usuário deverá informar dados completos, recentes e válidos, sendo de sua
                        exclusiva responsabilidade manter referidos dados atualizados, bem como o usuário se compromete
                        com a veracidade dos dados fornecidos.
                    </p>
                    <p class="text-body1">
                        O usuário se compromete a não informar seus dados cadastrais e/ou de acesso à plataforma a
                        terceiros, responsabilizando-se integralmente pelo uso que deles seja feito.
                    </p>
                    <p class="text-body1">
                        Menores de 18 anos e aqueles que não possuírem plena capacidade civil deverão obter previamente
                        o consentimento expresso de seus responsáveis legais para utilização da plataforma e dos
                        serviços ou produtos, sendo de responsabilidade exclusiva dos mesmos o eventual acesso por
                        menores de idade e por aqueles que não possuem plena capacidade civil sem a prévia autorização.
                    </p>
                    <p class="text-body1">
                        Mediante a realização do cadastro o usuário declara e garante expressamente ser plenamente
                        capaz, podendo exercer e usufruir livremente dos serviços e produtos.
                    </p>
                    <p class="text-body1">
                        O usuário deverá fornecer um endereço de e-mail válido, através do qual o site realizará todas
                        as comunicações necessárias.
                    </p>
                    <p class="text-body1">
                        Após a confirmação do cadastro, o usuário possuirá um <i>login</i> e uma senha pessoal, a qual
                        assegura ao usuário o acesso individual à mesma. Desta forma, compete ao usuário exclusivamente
                        a manutenção de referida senha de maneira confidencial e segura, evitando o acesso indevido às
                        informações pessoais.
                    </p>
                    <p class="text-body1">
                        Toda e qualquer atividade realizada com o uso da senha será de responsabilidade do usuário, que
                        deverá informar prontamente a plataforma em caso de uso indevido da respectiva senha.
                    </p>
                    <p class="text-body1">
                        Não será permitido ceder, vender, alugar ou transferir, de qualquer forma, a conta de acesso,
                        que é pessoal e intransferível.
                    </p>
                    <p class="text-body1">
                        Caberá ao usuário assegurar que o seu equipamento seja compatível com as características
                        técnicas que viabilize a utilização da plataforma e dos serviços disponibilizados.
                    </p>
                    <p class="text-body1">
                        O usuário, ao aceitar os Termos e Política de Privacidade, autoriza expressamente a plataforma a
                        coletar, usar, armazenar, tratar, ceder ou utilizar as informações derivadas do uso dos
                        serviços, do site e quaisquer plataformas, incluindo todas as informações preenchidas pelo
                        usuário no momento em que realizar ou atualizar seu cadastro, além de outras expressamente
                        descritas na Política de Privacidade que deverá ser autorizada pelo usuário.
                    </p>
                    <p class="text-h5">5 - Dos serviços</p>
                    <p class="text-body1">
                        A plataforma poderá disponibilizar para o usuário um conjunto específico de funcionalidades e
                        ferramentas para otimizar o uso dos serviços de vistoria eletrônica dos bens imóveis a serem
                        locados.
                    </p>
                    <p class="text-body1">
                        Antes de finalizar a contratação sobre o serviço, o usuário deverá se informar sobre as suas
                        especificações e sobre a sua funcionalidade conforme disposições contratuais.
                    </p>
                    <p class="text-h5">6 - Dos preços</p>
                    <p class="text-body1">
                        A plataforma se reserva no direito de reajustar unilateralmente, a qualquer tempo, os valores
                        dos serviços e seus pacotes, do que dará ciência prévia aos seus usuários.
                    </p>
                    <p class="text-body1">
                        Os valores a serem aplicados serão aqueles que estão em vigor no momento da contratação.
                    </p>
                    <p class="text-body1">
                        Os preços são indicados em reais.
                    </p>
                    <p class="text-body1">
                        Na contratação de determinado serviço, a plataforma poderá solicitar as informações financeiras
                        do usuário, como CPF, endereço de cobrança e dados de cartões. Ao inserir referidos dados o
                        usuário concorda que sejam cobrados, de acordo com a forma de pagamento que venha a ser
                        escolhida, os preços então vigentes e informados quando da contratação. Referidos dados
                        financeiros poderão ser armazenadas para facilitar acessos e contratações futuras.
                    </p>
                    <p class="text-h5">7 - Do cancelamento</p>
                    <p class="text-body1">
                        O usuário intermediador (pessoa jurídica e ou física) poderá cancelar a contratação dos serviços
                        de acordo com os termos que forem definidos no momento de sua contratação. Ainda, tal usuário
                        também poderá cancelar os serviços em até 7 (sete) dias após a contratação, mediante contato com
                        o contato@zonasete.com.br, de acordo com o Código de Defesa do Consumidor (Lei no. 8.078/90),
                        desde que efetivamente ainda não tenha utilizado dos serviços de vistorias de seu pacote de
                        contratação, se já estiver fazendo uso dos serviços, o prazo de rescisão será o disposto em
                        contrato.
                    </p>
                    <p class="text-body1">
                        O serviço poderá ser cancelado por:
                    </p>
                    <p class="text-body1">
                        a) parte do usuário: nessas condições os serviços somente cessarão após a conclusão das etapas
                        de vistoria até a emissão do laudo final em andamento.
                    </p>
                    <p class="text-body1">
                        b) violação dos Termos de Uso: os serviços serão cessados imediatamente.
                    </p>
                    <p class="text-h5">8 - Do suporte</p>
                    <p class="text-body1">
                        Em caso de qualquer dúvida, sugestão ou problema com a utilização da plataforma, o usuário
                        poderá entrar em contato com o suporte, através do email contato@zonasete.com.br.
                    </p>
                    <p class="text-body1">
                        Estes serviços de atendimento ao usuário estarão disponíveis nos seguintes dias e horários: de
                        segunda à sexta, das 08h às 18h.
                    </p>
                    <p class="text-h5">9 - Das responsabilidades</p>
                    <p class="text-body1">
                        É de responsabilidade dos usuários:
                    </p>
                    <p class="text-body1">
                        a) a ocorrência de defeitos ou vícios técnicos originados no próprio sistema do usuário;
                    </p>
                    <p class="text-body1">
                        b) a correta utilização da plataforma, dos serviços ou produtos oferecidos, prezando pela boa
                        convivência, pelo respeito e cordialidade entre os usuários;
                    </p>
                    <p class="text-body1">
                        c) pelo cumprimento e respeito ao conjunto de regras disposto nesse Termo de Condições Geral de
                        Uso, na respectiva Política de Privacidade e na legislação nacional aplicável;
                    </p>
                    <p class="text-body1">
                        d) pela proteção aos dados de acesso à sua conta/perfil (<i>login</i> e senha);
                    </p>
                    <p class="text-body1">
                        e) os usuários intermediares, sejam imobiliárias e ou corretores de imóveis, são os responsáveis
                        em orientar aos demais, locadores, locatários e fiadores, a efetivarem seus cadastros de acesso
                        à plataforma, aos registros dos dados, em especial os e-mails de contato para a efetivação e
                        acompanhamento dos laudos de vistorias, sejam de entrada e ou de saída. A liberação de acesso ao
                        imóvel, a entrega e a devolução das chaves pelos locatários, sem o cumprimento do registro e
                        assinatura digital nas vistorias, por estes, é de exclusiva e inteira responsabilidade dos
                        intermediadores contratantes;
                    </p>
                    <p class="text-body1">
                        f) os usuários locadores deverão efetivar seu cadastro e registro de dados, devendo sempre
                        acompanhar as etapas das vistorias realizadas, e ao final na emissão dos laudos, efetivar sua
                        assinatura digital;
                    </p>
                    <p class="text-body1">
                        g) os usuários locatários deverão efetivar seu cadastro e registro de dados, devendo sempre
                        acompanhar as etapas das vistorias realizadas através de seu e-mail cadastrado, seja a inicial
                        de entrada e ou de saída, devendo ainda, contestar se assim for o caso, as pré-vistorias, e ao
                        final na emissão dos laudos finalizados, efetivar a assinatura digital, sendo que <b>terão o
                        prazo de 48 (quarenta e oito) horas</b> para confirmar o recebimento e leitura da pré-vistoria
                        inicial e de saída, e não o fazendo neste prazo, presumir-se-á que concordam com o laudo
                        apresentado, assumindo assim, a responsabilidade futura quanto a qualquer contestação do estado
                        diverso do imóvel, em relação aos laudos definitivos emitidos;
                    </p>
                    <p class="text-body1">
                        h) os usuários fiadores, se estes forem os garantidores da locação, deverão efetivar seu
                        cadastro e registro de dados, devendo como os locatários, acompanhar todas as etapas das
                        vistorias realizadas, seja a de entrada e ou de saída, quando informados pelo e-mail cadastrado
                        em relação ao seu agendamento, devendo se for o caso, contestar a pré-vistoria, e ao final na
                        emissão dos laudos finalizados, efetivar a assinatura digital, devendo observar ao locatário
                        afiançado, a sua obrigação de também observar e acompanhar as pré-vistorias, e contestá-las se
                        for o caso, sendo que não o fazendo, assumem em conjunto as responsabilidades quanto à devolução
                        do imóvel no estado em que se encontrava, conforme laudos definitivos emitidos;
                    </p>
                    <p class="text-body1">
                        É de responsabilidade da plataforma:
                    </p>
                    <p class="text-body1">
                        a) indicar as características do serviço;
                    </p>
                    <p class="text-body1">
                        b) os defeitos e vícios encontrados no serviço oferecido, desde que lhe tenha dado causa;
                    </p>
                    <p class="text-body1">
                        c) as informações que foram por ele divulgadas, sendo que os comentários ou informações
                        divulgadas por usuários são de inteira responsabilidade dos próprios usuários;
                    </p>
                    <p class="text-body1">
                        A plataforma não se responsabiliza por links externos contidos em seu sistema que possam
                        redirecionar o usuário à ambiente externo a sua rede.
                    </p>
                    <p class="text-body1">
                        Não poderão ser incluídos links externos ou páginas que sirvam para fins comerciais ou
                        publicitários ou quaisquer informações ilícitas, violentas, polêmicas, pornográficas,
                        xenofóbicas, discriminatórias ou ofensivas.
                    </p>
                    <p class="text-h5">10 - Dos direitos autorais</p>
                    <p class="text-body1">
                        O presente Termo de Uso concede aos usuários uma licença não exclusiva, não transferível e não
                        sublicenciável, para acessar e fazer uso da plataforma e dos serviços e produtos por ela
                        disponibilizados.
                    </p>
                    <p class="text-body1">
                        A estrutura do site ou aplicativo, as marcas, logotipos, nomes comerciais, layouts, gráficos e
                        design de interface, imagens, ilustrações, fotografias, apresentações, vídeos, conteúdos
                        escritos e de som e áudio, programas de computador, banco de dados, arquivos de transmissão e
                        quaisquer outras informações e direitos de propriedade intelectual da GXZ Software
                        Ltda, são direitos exclusivos da contratada, excetuados apenas os laudos de avaliação
                        expedidos em interesse das partes usuárias, observados os termos da Lei da Propriedade
                        Industrial (Lei nº 9.279/96), Lei de Direitos Autorais (Lei nº 9.610/98) e Lei do Software
                        (Lei nº 9.609/98), estão devidamente reservados.
                    </p>
                    <p class="text-body1">
                        Este Termo de Uso não cede ou transfere ao usuário qualquer direito, de modo que o acesso não
                        gera qualquer direito de propriedade intelectual ao usuário, exceto pela licença limitada ora
                        concedida para a emissão dos laudos de vistoria.
                    </p>
                    <p class="text-body1">
                        O uso da plataforma pelo usuário é pessoal, individual e intransferível, sendo vedado qualquer
                        uso não autorizado, comercial ou não-comercial. Tais usos consistirão em violação dos direitos
                        de propriedade intelectual da GXZ Software Ltda, puníveis nos termos da legislação aplicável.
                    </p>
                    <p class="text-h5">11 - Das sanções</p>
                    <p class="text-body1">
                        Sem prejuízo das demais medidas legais cabíveis, a GXZ Software Ltda poderá, a qualquer
                        momento, advertir, suspender ou cancelar a conta do usuário:
                    </p>
                    <p class="text-body1">
                        a) que violar qualquer dispositivo do presente Termo;
                    </p>
                    <p class="text-body1">
                        b) que descumprir os seus deveres de usuário;
                    </p>
                    <p class="text-body1">
                        c) que tiver qualquer comportamento fraudulento, doloso ou que ofenda a terceiros.
                    </p>
                    <p class="text-h5">12 - Da recisão</p>
                    <p class="text-body1">
                        A não observância das obrigações pactuadas neste Termo de Uso ou da legislação aplicável poderá,
                        sem prévio aviso, ensejar a imediata rescisão unilateral por parte da GXZ Software Ltda e o
                        bloqueio de todos os serviços prestados ao usuário.
                    </p>
                    <p class="text-h5">13 - Das alterações</p>
                    <p class="text-body1">
                        Os itens descritos no presente instrumento poderão sofrer alterações, unilateralmente e a
                        qualquer tempo, por parte da contratada, para adequar ou modificar os serviços, bem como para
                        atender novas exigências legais. As alterações serão veiculadas pelo aplicativo e portal da
                        ZONA SETE VISTORIAS e os usuários poderão optar por aceitar o novo conteúdo ou por cancelar o
                        uso dos serviços.
                    </p>
                    <p class="text-h5">14 - Da política de privacidade</p>
                    <p class="text-body1">
                        Além do presente Termo, os usuários deverão consentir com as disposições contidas na respectiva
                        Política de Privacidade a ser apresentada a todos os interessados dentro da interface da
                        plataforma.
                    </p>
                    <p class="text-h5">15 - Do foro</p>
                    <p class="text-body1" ref="bottomElement">
                        Para a solução de controvérsias decorrentes do presente instrumento será aplicado integralmente
                        o Direito brasileiro. Os eventuais litígios deverão ser apresentados no foro da comarca em que
                        se encontra a sede da empresa.
                    </p>
                </div>
            </a-modal>
        );
    }
}
