import './index.less';
import { formatCpf } from "@/resources/util";
import ModalTerms from "@/components/modal-terms/modalTerms";
import ModalPrivacy from "@/components/modal-terms/modalPrivacy";

export default {

    created() {

        this.perfilComponent = () => import('./PerfilModal/index');

        this.currentMenu.push(this.$router.currentRoute.name);
    },
    computed: {

        session() {

            return this.$store.getters['session/user'];
        },
        stack() {

            return this.$route.matched.map(match => {

                return match.meta && match.meta.label ? match.meta.label.replace("/", "") : "";

            }).filter(match => match.trim())
        }
    },
    data() {

        return {

            showSearch: false,
            wasPerfilVisible: false,
            visiblePerfil: false,
            termosModal: false,
            privacidadeModal: false,
            currentMenu: []
        }
    },
    methods: {

        search(e) {

            console.log(e);
        },
        handleTogglePerfil() {

            if (!this.visiblePerfil && !this.wasPerfilVisible) this.wasPerfilVisible = true;
            this.visiblePerfil = !this.visiblePerfil;
        },
        handleMenuClick(item) {

            switch (item.key) {
                case 'perfil':
                    this.handleTogglePerfil();
                    return;
                case 'termos':
                    this.termosModal = true;
                    return;
                case 'politica':
                    this.privacidadeModal = true;
                    return;
                case 'sobre':
                    this.$info({
                        title: 'Sobre',
                        class: 'g-sobre-modal-content',
                        content: (
                            <div>
                                <p>&nbsp;</p>
                                <a href="https://zonasete.com.br" target="_blank">
                                    <img src={require('@/assets/logo.svg')} alt="Zona Sete" style="height: 30px;" />
                                </a>
                                <p>&nbsp;</p>
                                <p><strong>Desenvolvido por:</strong></p>
                                <a href="https://gxz.com.br" target="_blank">
                                    <img src={require('@/assets/logo-gxz.png')} alt="GXZ" style="height: 30px;" />
                                </a>
                                <p style="margin-top: 8px;">&copy; {(new Date()).getFullYear()} - Todos os direitos reservados.</p>
                            </div>
                        )
                    });
                    return;
                case 'logout':
                    this.$confirm({
                        title: 'Deseja realmente sair?',
                        okText: 'Sim',
                        okType: 'danger',
                        cancelText: 'Não',
                        maskClosable: true,
                        onOk: () => {
                            this.$store.dispatch('session/logout');
                        }
                    })
            }
        }
    },
    render() {

        let Perfil;

        if (this.wasPerfilVisible) {

            Perfil = <this.perfilComponent visible={this.visiblePerfil} onCancel={this.handleTogglePerfil} />;
        }

        return (
            <div id="top-header">
                {Perfil}
                <div
                    id="logo"
                    onClick={() => this.$router.push({name: "main"})}
                />
                <div style="display: flex; align-items: flex-end;">
                    <a-menu v-model={this.currentMenu} mode="horizontal">
                        {/*<a-menu-item key="dashboard" onClick={() => this.$router.push({name: "main"})}>*/}
                        {/*    <g-icon icon={['fas', 'home']} />*/}
                        {/*</a-menu-item>*/}
                        {
                            this.session.user && this.session.user.ator && (
                                <a-menu-item key="minhas-vistorias" onClick={() => this.$router.push("/minhas-vistorias")}>
                                    <g-icon icon={['fas', 'clipboard-list']} fixed-width/>
                                    <span class="hidden-sm">Minhas vistorias</span>
                                </a-menu-item>
                            )
                        }
                        {
                            /* this.session.user && this.session.user.ator && (
                                <a-menu-item key="contratos" onClick={() => this.$router.push("/contratos")}>
                                    <g-icon icon={['fas', 'file-contract']} fixed-width/>
                                    <span class="hidden-sm">Contratos</span>
                                </a-menu-item>
                            ) */
                        }
                        {
                            this.session.user && this.session.user.cliente && (
                                <a-menu-item key="vistorias" onClick={() => this.$router.push("/vistorias")}>
                                    <g-icon icon={['fas', 'clipboard-list']} fixed-width/>
                                    <span class="hidden-sm">Vistorias</span>
                                </a-menu-item>
                            )
                        }
                        {
                            this.session.user && this.session.user.cliente && (
                                <a-menu-item key="faturas" onClick={() => this.$router.push("/faturas")}>
                                    <g-icon icon={['fas', 'file-invoice-dollar']} fixedWidth />
                                    <span class="hidden-sm">Faturas</span>
                                </a-menu-item>
                            )
                        }
                        {
                            this.session.user && this.session.user.cliente && (
                                <a-menu-item key="cotacao" onClick={() => this.$router.push("/cotacao")}>
                                    <g-icon icon={['fas', 'dollar-sign']} fixedWidth />
                                    <span class="hidden-sm">Cotação de Preços</span>
                                </a-menu-item>
                            )
                        }
                    </a-menu>
                </div>
                <div id="top-header-right">
                    <div>
                        <div id="top-header-right-menu" class="hidden-md">
                            {
                                this.session.user && this.session.user.cliente && (
                                    <div class="trigger hidden-sm">
                                        <span>
                                            <g-icon icon={['far', 'sign']} /> {this.session.user.cliente.pessoa === "J" ? (this.session.user.cliente.nome_fantasia || this.session.user.cliente.razao_social) : this.session.user.cliente.nome}
                                        </span>
                                    </div>
                                )
                            }
                            <div class="trigger" onClick={this.handleTogglePerfil}>
                                <span>
                                    <g-icon icon={['far', 'user']} />
                                    &nbsp;
                                    {this.session.user && this.session.user.name ? this.session.user.name.split(' ')[0] : ''}
                                </span>
                            </div>
                        </div>
                        <a-dropdown id="top-header-right-dropdown" placement="bottomRight">
                            {
                                this.session.user && this.session.user.avatar ? (
                                    <a-avatar src={this.session.user.avatar} />
                                ) : (
                                    <a-avatar>{this.session.user && this.session.user.name ? this.session.user.name.charAt(0).toUpperCase() : ''}</a-avatar>
                                )

                            }
                            <a-menu slot="overlay" onClick={this.handleMenuClick}>
                                <a-menu-item class="not-link">
                                    <strong>Usuário: </strong>{this.session.user ? this.session.user.username : ''}
                                </a-menu-item>
                                <a-menu-item class="not-link">
                                    <strong>CPF: </strong>{this.session.user && this.session.user.cpf ? formatCpf(this.session.user.cpf) : ''}
                                </a-menu-item>
                                <a-menu-divider />
                                {
                                    this.session.user && this.session.user.cliente && (
                                        <a-menu-item class="hidden-xl">
                                            <g-icon icon={['far', 'sign']} fixed-width />
                                            {this.session.user.cliente.pessoa === "J" ? (this.session.user.cliente.nome_fantasia || this.session.user.cliente.razao_social) : this.session.user.cliente.nome}
                                        </a-menu-item>
                                    )
                                }
                                <a-menu-item key="perfil">
                                    <g-icon icon={['far', 'user']} fixedWidth />
                                    <span class="hidden-lg hidden-xl">
                                        {this.session.user && this.session.user.name || ''}
                                    </span>
                                    <span class="hidden-sm hidden-md">Perfil</span>
                                </a-menu-item>
                                <a-menu-item key="termos">
                                    <g-icon icon={['far', 'file-contract']} fixedWidth />
                                    Termos de uso
                                </a-menu-item>
                                <a-menu-item key="politica">
                                    <g-icon icon={['far', 'user-secret']} fixedWidth />
                                    Política de privacidade
                                </a-menu-item>
                                <a-menu-item key="sobre">
                                    <g-icon icon={['far', 'info-circle']} fixedWidth />
                                    Sobre
                                </a-menu-item>
                                <a-menu-divider />
                                <a-menu-item key="logout">
                                    <g-icon icon={['far', 'sign-out-alt']} fixedWidth />
                                    Logout
                                </a-menu-item>
                            </a-menu>
                        </a-dropdown>
                    </div>
                </div>

                <ModalTerms
                    visible={this.termosModal}
                    readonly
                    onAcceptTerm={() => this.termosModal = false}
                />

                <ModalPrivacy visible={this.privacidadeModal} onClose={() => this.privacidadeModal = false} />
            </div>
        );
    }
}
