import './index.less';
import TopBar from "./top-bar";
import ptBr from "ant-design-vue/es/locale-provider/pt_BR";

export default {

    render() {

        return (
            <a-config-provider locale={ptBr}>
                <div id="main">
                    <TopBar />
                    <div id="container">
                        <div id="content">
                            <div id="view">
                                <router-view />
                            </div>
                        </div>
                    </div>
                </div>
            </a-config-provider>
        )
    }
}
